import { useEffect } from 'react';

import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { ImprovedNoise } from 'three/examples/jsm/math/ImprovedNoise.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { Line2 } from 'three/examples/jsm/lines/Line2.js';

import findPath from './findpath';

function App() {
  useEffect(() => {
    const { renderer, scene, vertices } = Sim();
    document.body.appendChild(renderer.domElement);

    // prevent flashing screen with setTimeout
    setTimeout(() => {
      const path = createFatPath(vertices);
      scene.add(path);
    }, 1);
  });

  return null;
}

const Sim = () => {
  var width  = window.innerWidth,
      height = window.innerHeight;

  const scene = new THREE.Scene();

  const axes = new THREE.AxesHelper(200);
  scene.add(axes);

  const camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 10000);
  camera.position.set(500, 1200, 500);
  camera.rotation.z = 0.5 * Math.PI;

  const renderer = new THREE.WebGLRenderer();
  renderer.setSize(width, height);
  // renderer.setClearColor(new THREE.Color(0xffffff))

  const { groundMesh, vertices } = createGround();
  scene.add(groundMesh);

  const ambientLight = new THREE.AmbientLight(0xffffff, 2000);
  scene.add(ambientLight);

  // const directionLight = new THREE.DirectionalLight(0xffffff, 20);
  // scene.add(directionLight);

  const orbit = new OrbitControls(camera, renderer.domElement);
  orbit.update();

  renderer.setAnimationLoop(() => renderer.render(scene, camera));

  window.addEventListener('resize', function() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
  });

  return { renderer, scene, vertices };
}

const createGround = () => {
  const groundGeo = new THREE.PlaneGeometry(1000, 1000, 100, 100);

  const vertices: any = groundGeo.attributes.position.array;
  const m = vertices.length / 3;
  const n = Math.sqrt(m);
  let data: any = generateHeight(n, n);
  console.log(`Nb vertices: ${m} / Grid size: ${n}`);
  console.log(`Max: ${Math.max(...data)}, Min: ${Math.min(...data)}`);

  for ( let i = 0; i < m; i ++) {
    vertices[i * 3 + 2] = 1.75*data[i] // z -> green;
  }

  const groundMat = new THREE.MeshStandardMaterial({
    color: 'yellow',
    wireframe: true,
  });

  const groundMesh = new THREE.Mesh(groundGeo, groundMat);
  groundMesh.rotation.x = -0.5 * Math.PI;

  return { groundMesh, vertices };
}

function createFatPath(vertices: any) {
  // find optimal path
  const path = findPath(vertices)!;

  const points: any[] = [];
  path.forEach((pos: any) => {
    points.push(pos.x, pos.z, pos.y);
  });

  const geometry = new LineGeometry();
  geometry.setPositions(points)

  const material = new LineMaterial( {
    color: 0xff0000,
    linewidth: 4,
    worldUnits: true,
    dashed: false,
  });

  const line = new Line2( geometry, material );
  line.computeLineDistances();
  line.scale.set( 1, 1, 1 );
  line.rotation.y = 0.5 * Math.PI;

  return line;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const createPath = (vertices: any) => {
  // find optimal path
  const path = findPath(vertices)!;

  const points: any[] = [];
  path.forEach((pos: any) => {
    points.push( new THREE.Vector3( pos.x, pos.z, pos.y ) );
  });

  const geometry = new THREE.BufferGeometry().setFromPoints( points );
  const material = new THREE.LineBasicMaterial( { color: 'red', linewidth: 1 } );
  const line = new THREE.Line( geometry, material );
  line.rotation.y = 0.5 * Math.PI;
  return line;
};

const generateHeight = (width: number, height : number) => {
  const size = width * height,
    data = new Uint8Array( size ),
    perlin = new ImprovedNoise(),
    z = Math.random() * 100;

  let quality = 1;

  for ( let j = 0; j < 4; j ++ ) {
    for ( let i = 0; i < size; i ++ ) {
      const x = i % width, y = ~ ~ ( i / width );
      data[ i ] += Math.abs( perlin.noise( x / quality, y / quality, z ) * quality * 1.75 );
    }

    quality *= 5;
  }

  return data;
}

export default App;
