import Heap from 'heap';

type Position = {
  x:          number;
  y:          number;
  z:          number;
  i:          number;
  j:          number;
  k:          number;
  score:      number;
}

const yUnit = 1;
const minK = 20;

const findPath = (vertices: any) => {
  const startDate = new Date();
  const map = toMap(vertices);
  const n = map.length;

  const src: Position = findLowestPosition(map, 0);
  src.k += minK
  src.z = src.k * yUnit
  console.log('src', src)

  const dest: Position = findLowestPosition(map, n-1);
  dest.k += minK
  dest.z = dest.k * yUnit
  console.log('dest', dest)

  const costs: any = {};
  costs[`${src.i}-${src.j}-${src.k}`] = 0

  const visited: any = {};
  visited[`${src.i}-${src.j}-${src.k}`] = 0

  var heap = new Heap((a: Position, b: Position) => a.score - b.score);
  heap.push(src);

  let c = 0;
  const origins: any = {};

  while (heap.size() > 0) {
    c++
    const pos: Position = heap.pop() as Position;
    const key = `${pos.i}-${pos.j}-${pos.k}`;

    if (c > 1000000) {
      console.log(`Exceeded max number of iterations`)
      return [];
    }

    if (pos.i === dest.i && pos.j === dest.j && pos.z === dest.z) {
      const path = reconstructPath(pos, origins)
      var endDate = new Date();
      var milliseconds = (endDate.getTime() - startDate.getTime());
      console.log(`Found optimal path (took ${milliseconds} ms)`, c);
      return path;
    }

    const ks = [
      pos.k, // same altitude
      pos.k - 1, // go down
    ];

    if (pos.k < 150) {
      ks.push(pos.k + 1) // go up
    }

    const ijs = [
      [pos.i,   pos.j],
      [pos.i,   pos.j-1],
      [pos.i,   pos.j+1],
      [pos.i-1, pos.j],
      [pos.i-1, pos.j-1],
      [pos.i-1, pos.j+1],
      [pos.i+1, pos.j],
      [pos.i+1, pos.j-1],
      [pos.i+1, pos.j+1],
    ];

    ks.forEach((k: any) => {
      ijs.forEach((arr: any) => {
        const i = arr[0];
        const j = arr[1];

        if (i === pos.i && pos.j === j && pos.k === k) {
          return;
        }

        if (map[i] === undefined) {
          return;
        }

        if (map[i][j] === undefined) {
          return;
        }

        const ground = map[i][j];

        if (k < ground.k) {
          return;
        }

        const neighborPos = {
          i: i,
          j: j,
          k: k,
          x: ground.x,
          y: ground.y,
          z: k * yUnit,
          score: 0,
        }

        const distance = computeDistance(pos, neighborPos);
        const risk = k < (ground.k + minK) ? 1000 : 0;
        const edgeCost = distance + risk;
        const cost = costs[key] + edgeCost;

        const neighborKey = `${i}-${j}-${k}`;
        const prevCost = costs[neighborKey];

        if (prevCost === undefined || cost < prevCost) {
          costs[neighborKey] = cost;
          origins[neighborKey] = pos;

          if (visited[neighborKey] === undefined) {
            neighborPos.score = cost + computeDistance(neighborPos, dest);
            heap.push(neighborPos);
            visited[neighborKey] = true;
          }
        }
      });
    });
  }

  console.log('NOT FOUND')
  return [src, dest];
};

const toMap = (vertices: any) => {
  const n = Math.sqrt(vertices.length / 3);
  const map: any = [];

  let k = 0;
  for (var i = 0; i < n; i++) {
    map[i] = new Array(n);
    for (var j = 0; j < n; j++) {
      const altitude = vertices[k+2]

      map[i][j] = {
        x: vertices[k+1],
        y: vertices[k],
        z: altitude,
        i: i,
        j: j,
        k: zIndex(altitude),
      };
      k += 3
    }
  }

  return map;
};

// const computeAllDistances = (map: any, dest: Position) => {
//   const n = map.length;
//   for (var i = 0; i < n; i++) {
//     for (var j = 0; j < n; j++) {
//       const pos = map[i][j];
//       pos.heuristic = computeDistance(pos, dest);;
//     }
//   }
// };

const computeDistance = (src: Position, dest: Position) => {
  return Math.sqrt(
    (src.x - dest.x)**2 +
    (src.y - dest.y)**2 +
    (src.z - dest.z)**2
  )
};

const zIndex = (y: number) => {
  return Math.floor(y / yUnit);
};

const findLowestPosition = (map: any, i: number) => {
  const n = map.length;
  const start = Math.floor(n * 0.25);
  const end = Math.floor(n * 0.75);

  let index = start;
  let min = map[i][start].z;
  for (var i0 = start; i0 < end+1; i0++) {
    if (map[i][i0].z < min) {
      min = map[i][i0].z;
      index = i0;
    }
  }
  return map[i][index];
}

const reconstructPath = (pos: Position, origins: any) => {
  const path: any[] = [pos];
  let currentPos: (Position | undefined) = pos;

  while (currentPos !== undefined) {
    const key: any = `${currentPos.i}-${currentPos.j}-${currentPos.k}`;
    path.push(currentPos);
    currentPos = origins[key]
  }

  return path.reverse();
}

export default findPath;